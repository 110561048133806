var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Bi2T00Gdox4uq6xFIZRvh"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

if (publicRuntimeConfig.sentryEnabled) {
    Sentry.init({
        dsn: 'https://d0eb89039f194b238c041baeae779bfc@o1269269.ingest.sentry.io/4504361948610560',

        // Adjust this value in production, or use tracesSampler for greater control
        tracesSampleRate: 0,

        // Setting this option to true will print useful information to the console while you're setting up Sentry.
        debug: false,

        replaysOnErrorSampleRate: 1.0,

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0,

        // You can remove this option if you're not planning to use the Sentry Session Replay feature:
        integrations: [
            new Sentry.Replay({
                // Additional Replay configuration goes in here, for example:
                maskAllText: true,
                blockAllMedia: true,
            }),
        ],

        environment: publicRuntimeConfig.sentryEnvironment,
    });
}
